export const HOLIDAY_EVENTS = {
  birthday: "birthday",
  blackFriday: "blackFriday",
  halloween: "halloween",
  thanksGiving: "thanksGiving",
  christmas: "christmas",
  canadaDay: "canadaDay",
  independentDay: "independentDay",
  contestEarlyBirds: "contestEarlyBirds",
  contestBasic: "contestBasic",
  contestLastCall: "contestLastCall",
  contestLeaderboards: "contestLeaderboards",
};

export const MONTHS = {
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
};

export const MILISECONDS_PER_HOURS = 3600000;
export const MILISECONDS_PER_MINUTES = 60000;
export const SECONDS_PER_MINUTE = 60;
export const HOURS_PER_DAY = 24;
export const MILISECOND_PER_SECOND = 1000;

export const HOLIDAY_EVENTS_DATE = {
  blackFriday: [
    { month: MONTHS.NOVEMBER, date: 21, hours: 8, minutes: 0, seconds: 0 },
    { month: MONTHS.NOVEMBER, date: 28, hours: 8, minutes: 0, seconds: 0 },
  ],
  christmas: [
    { month: MONTHS.DECEMBER, date: 18, hours: 8, minutes: 0, seconds: 0 },
    { month: MONTHS.DECEMBER, date: 27, hours: 8, minutes: 0, seconds: 0 },
  ],
  independentDay: [
    { month: MONTHS.JULY, date: 4, hours: 4, minutes: 0, seconds: 0 },
    { month: MONTHS.JULY, date: 5, hours: 10, minutes: 0, seconds: 0 },
  ],
  contestEarlyBirds: [
    { month: MONTHS.SEPTEMBER, date: 13, hours: 8, minutes: 0, seconds: 0 },
    { month: MONTHS.SEPTEMBER, date: 16, hours: 8, minutes: 0, seconds: 0 },
  ],
  contestBasic: [
    { month: MONTHS.SEPTEMBER, date: 16, hours: 8, minutes: 0, seconds: 0 },
    { month: MONTHS.SEPTEMBER, date: 23, hours: 8, minutes: 0, seconds: 0 },
  ],
  contestLastCall: [
    { month: MONTHS.SEPTEMBER, date: 23, hours: 8, minutes: 0, seconds: 0 },
    { month: MONTHS.SEPTEMBER, date: 30, hours: 8, minutes: 0, seconds: 0 },
  ],
  contestLeaderboards: [
    { month: MONTHS.SEPTEMBER, date: 30, hours: 8, minutes: 0, seconds: 0 },
    { month: MONTHS.OCTOBER, date: 6, hours: 8, minutes: 0, seconds: 0 },
  ],
  halloween: [
    { month: MONTHS.OCTOBER, date: 31, hours: 8, minutes: 0 },
    { month: MONTHS.NOVEMBER, date: 4, hours: 8, minutes: 0 },
  ],
  thanksGiving: [
    { month: MONTHS.NOVEMBER, date: 1, hours: 8, minutes: 0 },
    { month: MONTHS.DECEMBER, date: 4, hours: 8, minutes: 0 },
  ],
};
